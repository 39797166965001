.menu-item{
	margin: 5px 0;
}
.menu-item .menu{
	margin: 0 auto;
	width: 3.5em;
	height: 3.5em;
	background-color: #bbb;
	border-radius: 15px;
}
.menu-item p{
	margin-top: 5px;
	margin-bottom: 0;
	color: #eee;
	text-align: center;
	font-size: .8em;
}