:root {
  --abu-abu0: #fafafa;
  --abu-abu: #E5E5E5;
  --abu-abu2: #ccc;
  --hijau: #178E2B;
  --hijau2: #009946;
  --hitam: #373639;
  --biruungu: #150d56;
}

body {
  background-color: var(--abu-abu);
}
p 	{ margin: 0; }
hr 	{ margin: 0; }

ul.pagination .page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: var(--hijau2);
  border-color: var(--hijau);
}
.page-link{
  color: gray;
}
/* === class tambahan ===*/
  a.clear-link{
  	text-decoration: none;
  	color: var(--hitam);
  }
  .w-fit-content{
  	width: fit-content;
  }

  .space{height:70px;}
  .be-footer{
    position: fixed;
    bottom: 0;
    max-width: 600px;
    z-index: 99;
  }
  .text-blue{
    color: #150d56;
  }
/* === Section Menu List === */
  .sec{
    background: #009946;
    padding: 15px 0;
    border-radius: 25px;
    height: 100%;
    margin: 10px;
  }
/*=== Stok ===*/
  .stok-nav a{
    color: black;
    font-weight: 500;
  }
  .stok-nav hr{
    width: 100%;
    margin-top: 5px;
  }
  .stok-nav hr.active{
    background-color: #009946;
  }