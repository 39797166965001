#alert{
	position: absolute;
	top: 20%;
	z-index: 100;
	background-color: var(--abu-abu0);
	width: 90%;
	max-width: 600px;
	margin: 0 auto;
	left: 0;
	right: 0;
	padding: 2.5em 3em 1em;
}