/* === Header === */
header {
  margin: 0 auto;
  position: fixed;
  right: 0;
  left: 0;
  z-index: 10;
  max-width: 600px;
}
.header{
  padding: 20px 20px 5px 20px;
  background-color: #fff;
}
header p{
  text-align: center;
  font-size: 12px;
  background: #009946;
  color: #fff;
  display: block;
  padding: 5px 0;
  width: 100%;
  margin: 0;
}

/* === Bottom Nav === */
nav#navbawah{
  max-width: 600px;
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: #fff;
  display: inline-block;
  border-radius: 20px 20px 0 0;
}
nav .menus-wrapper{
  width: fit-content;
  margin: 0 auto;
  display: block;
  height: 60px;
  vertical-align: middle;
}
.menu-wrapper{
  padding: 7px 5px;
  text-align: center;
  display: inline-block;
}
.menu-wrapper p{
  font-weight: bold;
  font-size: 12px;
  color: #333;
}
/*menu-active*/
.active_ p{
  color: #009946;
}
.active_ path{
  fill: #009946;
}

/* === Sidebar === */
.fullscreen-blur{
  position: fixed;
  display: inline-block;
  top: 0;
  bottom: 0;
  left: 0;
  width: 0;
  z-index: 100;
  background-color: #5550;
  transition: background-color 1s;
}
.fullscreen-blur.opened{
  width: 100%;
  background-color: #555c;
}
.sidebar{
  padding: 15px;
  text-align: right;
  width: 70%;
  max-width: 500px;
  position: fixed;
  right: -100%;
  transition: right 1s;
  bottom: 0;
  top: 0;
  border-radius: 30px 0 0 30px;
  z-index: 101;
  background-color: #eee;
}
.sidebar.opened{
  right: 0;
}
.sidebar img{
  width: 80%;
  margin: 5px;
}
.sidebar a{
  color: #444;
  font-size: 1.3em;
}
.sidebar .copyright{
  display: block;
  width: 100%;
  position: absolute;
  bottom: 0px;
  text-align: center;
  font-style: italic;
  margin: 0 auto;
}
.sidebar p{
  text-align: center;
}
