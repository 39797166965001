#popup{
	position: fixed;
	top: 20%;
	z-index: 100;
	background-color: var(--abu-abu0);
	padding: 50px 0 30px;
	border-radius: 1em;
	width: 90%;
	margin: 0 auto;
	left: 0;
	right: 0;
	max-width: 580px;
}
#bg{
	width: 100%;
	height: 100%;
	position: fixed;
	top: 0;
	opacity: .8	;
	z-index: 99;
	background: var(--abu-abu2);
}
.close{
	color: red;
	position: absolute;
	display: inline-block;
	top: 15px;
	right: 25px;
}