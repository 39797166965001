
/* =button= */
.sec-1 button.sec-button{
	padding: 10px 0;
	margin-bottom: 10px;
	font-size: 14px;
	font-weight: 600;
	width: 100%;
	box-shadow: 0 3px 5px grey;
}
/* =stock= */
.stock-box{
	text-align: center;
	border-radius: 20px;
	background-image: linear-gradient(#ccc, #fff, #fff, #fff);
	box-shadow: 0 3px 3px grey;
	padding: 5px;
	width: 4.6em;
	height: 4.6em;
	margin: 5px;
}
.stock-box h1{
	font-size: 2em;
	color: #150d56;
	margin-bottom: 0;
}
.stock-box p{
	font-size: .6em;
	margin-bottom: 0;
}


